/* eslint-disable react-hooks/exhaustive-deps */
import {useMatch, useNavigate} from 'react-router';
import {FunctionComponent, useEffect, useRef} from 'react';

import {api} from 'api';
import {useLogout} from 'modules/auth';
import {useMyId} from 'modules/auth/hooks';

import {TAuthProvider} from './types';

const AuthProvider: FunctionComponent<TAuthProvider> = ({children}) => {
  const authRef = useRef(false);
  const navigate = useNavigate();
  const logout = useLogout();
  const isInvite = useMatch('invite/:hash');
  const isPrivacy = useMatch('privacyPolicy');
  const isRedirect = useMatch('redirect');
  const isAgreement = useMatch('userAgreement');
  const match = isInvite || isPrivacy || isAgreement || isRedirect;

  api.endpoints.settingsIndex.useQuery();
  const isAuth = Boolean(useMyId());

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {refetch, isLoading, isFetching, isError} = api.useProfileIndexQuery(undefined);
  useEffect(() => {
    if (authRef.current && isError) logout();
  }, [isError, logout]);

  useEffect(() => {
    if (isAuth !== authRef.current) {
      if (!isAuth) navigate('/welcome');
      authRef.current = isAuth;
    }
    if (!isFetching && !isAuth && !match) navigate('/welcome');
  }, [isAuth, isFetching, match]);

  if (isLoading) return null;
  return <>{children}</>;
};

export const AuthProviderLoader: FunctionComponent<TAuthProvider> = ({children}) => {
  return <AuthProvider>{children}</AuthProvider>;
};

export default AuthProviderLoader;
