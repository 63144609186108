import React from 'react';
import ReactDOM from 'react-dom';
import {CssBaseline} from '@mui/material';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import numeral from 'numeral';
import {initAnalytics, LOG_OPEN_APP} from './utils/analytics';

if (!window.ResizeObserver) {
  const loadObserver = async () => {
    window.ResizeObserver = (await import('resize-observer-polyfill')).default;
  };
  loadObserver();
}

numeral.register('locale', 'ru', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'тыс.',
    million: 'млн.',
    billion: 'млрд.',
    trillion: 'трлн.',
  },
  ordinal: function () {
    // not ideal, but since in Russian it can taken on
    // different forms (masculine, feminine, neuter)
    // this is all we can do
    return '.';
  },
  currency: {
    symbol: 'руб.',
  },
});
numeral.locale('ru');
initAnalytics();
LOG_OPEN_APP({APP_NAME: 'THE-GAME', WHO: 'MAXIM'});

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
