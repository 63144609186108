import {useState} from 'react';

import {Box, Collapse, Button, Stack} from '@mui/material';
import {ChevronRight} from '@mui/icons-material';

import {StageInfo} from 'api/generated/users-api';

import {QuestPointView} from 'modules/oldQuests2/components/QuestPoint';
import {QuestPointContent} from 'modules/oldQuests2/containers/QuestPoint/QuestPoint';
import {api} from 'api';

export const QuestSubscriptionsPoints = ({subscriptionsStages}: {subscriptionsStages: StageInfo[]}) => {
  const [expand, setExpand] = useState(false);
  return (
    <Box pl={6}>
      <Button
        variant="text"
        onClick={() => setExpand(i => !i)}
        sx={{
          width: 'fit-content',
          height: 'fit-content',
          color: 'grey.200',
          py: 0.25,
          pl: 1,
          pr: 0.5,
          fontSize: 15,
          lineHeight: '18px',
        }}
      >
        Подписки
        <Box
          component={ChevronRight}
          ml={0.5}
          sx={{transform: expand ? 'rotate(270deg)' : 'rotate(90deg)', transition: 'transform .2s'}}
        />
      </Button>
      <Collapse in={expand} mountOnEnter unmountOnExit>
        {subscriptionsStages.map(i => (
          <Stage key={i.stage._id} stageInfo={i} />
        ))}
      </Collapse>
    </Box>
  );
};

const Stage = ({stageInfo}: {stageInfo: StageInfo}) => {
  const quest = stageInfo.stage.quest;
  const {data = []} = api.endpoints.stagePointsIndex.useQuery({stageId: stageInfo.stage._id!});

  if (!quest) return null;
  return (
    <Stack>
      {data.map(pointData => (
        <QuestPointContent
          key={pointData.questPointData.questPoint._id}
          collapsedAll={false}
          Component={QuestPointView}
          stagePoint={pointData.stagePoint}
          questPointData={pointData.questPointData}
        />
      ))}
    </Stack>
  );
};
