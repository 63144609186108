import {Suspense, useEffect, useMemo} from 'react';
import {QueryParamProvider} from 'use-query-params';
import {useLocation, useNavigate, useMatch, useRoutes, Navigate, Outlet} from 'react-router-dom';

import {ErrorPage, Layout} from 'components/Layout';

import feedRoute from 'modules/feed';
import tasksRoute from 'modules/tasks';
import goalsRoute from 'modules/goals';
import groupsRoute from 'modules/groups';
import searchRoute from 'modules/search';
import profileRoutes from 'modules/profile';
import contentsRoute from 'modules/contents';
import {GoalReportFormProvider} from 'modules/goals/containers';
import {GoalFormProvider} from 'modules/goals/containers/GoalFormProvider';
import authRoutes, {settingsRoute, useMyId} from 'modules/auth';
import {DialogViewProvider} from 'components/Dialog/DialogView';
import {PrivacyPoilicyPage, UserAgreementPage} from 'modules/policies';
import {RedirectScreen} from 'modules/redirect';
import {postRouter} from 'modules/feed/router';

export const MainRouter = () => {
  const isAuth = Boolean(useMyId());
  const isInvite = useMatch('invite/:hash');
  const isPrivacy = useMatch('privacyPolicy');
  const isRedirect = useMatch('redirect');
  const isAgreement = useMatch('userAgreement');
  const routes = useRoutes([
    {
      index: true,
      element:
        !isPrivacy && !isAgreement && !isRedirect ? (
          <Navigate to={isAuth && !isInvite ? '/my-profile' : '/welcome'} />
        ) : (
          <Outlet />
        ),
    },
    ...profileRoutes,
    {
      element: <Layout />,
      children: [feedRoute, postRouter, goalsRoute, tasksRoute, searchRoute, settingsRoute, contentsRoute, groupsRoute],
    },
    authRoutes,
    {
      path: 'redirect',
      element: <Outlet />,
      children: [{index: true, element: <RedirectScreen />}],
    },
    {
      path: 'privacyPolicy',
      element: isAuth ? <Layout /> : <Outlet />,
      children: [{index: true, element: <PrivacyPoilicyPage />}],
    },
    {
      path: 'userAgreement',
      element: isAuth ? <Layout /> : <Outlet />,
      children: [{index: true, element: <UserAgreementPage />}],
    },
    {
      path: '*',
      element: (
        <Layout>
          <ErrorPage />
        </Layout>
      ),
    },
  ]);

  if (!isAuth) return <>{routes}</>;
  return (
    <DialogViewProvider>
      <GoalFormProvider>
        <GoalReportFormProvider>{routes}</GoalReportFormProvider>
      </GoalFormProvider>
    </DialogViewProvider>
  );
};

const RouteAdapter = ({children}: {children?: any}) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const adaptedHistory = useMemo(
    () => ({
      replace(newLocation: any) {
        navigate(newLocation, {replace: true, state: newLocation.state});
      },
      push(newLocation: any) {
        navigate(newLocation, {replace: false, state: newLocation.state});
      },
    }),
    [navigate]
  );
  return children({history: adaptedHistory, location});
};

export const AppRouter = () => (
  <QueryParamProvider ReactRouterRoute={RouteAdapter}>
    <Suspense fallback={<Layout />}>
      <MainRouter />
    </Suspense>
  </QueryParamProvider>
);

export default AppRouter;
