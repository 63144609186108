import {useIdle} from 'react-use';
import {useUpdateAtom} from 'jotai/utils';
import {minutesToMilliseconds} from 'date-fns';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
// import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import {api} from 'api';
// import {ReactComponent as SettingsIcon} from 'svg/icons/settingsIcon.svg';
import {ReactComponent as ChevronRight} from 'svg/chevrons/chevronRight.svg';
import {closeNotificationDialog, openNotificationFullDialog} from 'modules/notifications/store';
import {useMyId} from 'modules/auth';

import {NotificationItem} from '..';
import {Transition} from './Transition';
import {EmptyList} from 'components/EmptyList';
import {NotificationGroupInviteItem} from '../NotificationGroupInviteItem';
import {NotificationGroupRequestItem} from '../NotificationGroupRequestItem';

export const NotificationsDialogPreview = () => {
  const close = useUpdateAtom(closeNotificationDialog);
  const openFull = useUpdateAtom(openNotificationFullDialog);
  const isIdle = useIdle(minutesToMilliseconds(5));
  const myId = useMyId();
  const {data} = api.endpoints.notificationsIndex.useQuery(
    {unseenOnly: 1},
    {refetchOnMountOrArgChange: true, pollingInterval: minutesToMilliseconds(1), skip: isIdle}
  );
  const {data: settings} = api.endpoints.settingsIndex.useQuery();
  const [markReadAll, {isLoading}] = api.endpoints.notificationsReadAll.useMutation();
  const {data: invitesData} = api.endpoints.groupInvitesIndex.useQuery(
    settings?.groups?.allowInvites ? {forMe: true} : skipToken
  );
  const {data: requestsData} = api.endpoints.groupRequestsIndex.useQuery(
    settings?.groups?.allowRequests ? {} : skipToken
  );

  const items = data?.data || [];
  const invites = invitesData?.data?.filter(i => i.invitedUserId === myId) || [];
  const requests = requestsData?.data?.filter(i => i.user?._id !== myId) || [];
  const unreadCount =
    (data?.unreadCount || 0) +
      invites.length +
      requests.filter(
        i => !Boolean(i.approvedUsersId.find(id => id === myId) || i.declinedUsersId.find(id => id === myId))
      ).length || 0;
  return (
    <Dialog
      open
      onClose={close}
      onBackdropClick={close}
      TransitionComponent={Transition}
      closeAfterTransition
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
        },
      }}
      PaperProps={{
        sx: {
          m: 2,
          mt: 7,
          width: '100%',
          height: '70vh',
          borderRadius: 2,
          boxShadow: '0px 0px 2px rgba(16, 24, 40, 0.06), 0px 24px 48px -12px rgba(16, 24, 40, 0.25)',
        },
      }}
      hideBackdrop
    >
      <Box sx={{position: 'relative', width: 1 / 1}}>
        <Box
          onClick={openFull}
          component={ButtonBase}
          sx={{px: 2, py: 1, alignItems: 'center', justifyContent: 'flex-start', width: 1 / 1}}
        >
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography sx={{fontWeight: 500}}>
              <span>Уведомления</span>
              {unreadCount > 0 && (
                <Typography sx={{color: '#EC096F', ml: 1}} component="span">
                  {unreadCount}
                </Typography>
              )}
            </Typography>
            <ChevronRight />
          </Stack>
        </Box>
        {/* <IconButton size="small" sx={{p: 0, position: 'absolute', right: 8, top: 8}}>
          <Box component={SettingsIcon} sx={{color: 'grey.200', width: 24, height: 24}} />
        </IconButton> */}
      </Box>

      <DialogContent sx={{p: 0}}>
        {!(items.length + invites.length + requests.length) && (
          <EmptyList
            text="Новых уведомлений нет"
            action={
              <Button size="small" color="secondary" variant="contained" onClick={openFull}>
                Посмотреть все
              </Button>
            }
          />
        )}
        {invites.map(invite => (
          <NotificationGroupInviteItem key={invite._id} invite={invite} />
        ))}
        {requests.map(request => (
          <NotificationGroupRequestItem key={request._id} request={request} />
        ))}
        {items.map(item => (
          <NotificationItem key={item._id} notification={item} />
        ))}
        {items.length < unreadCount && (
          <Box sx={{pt: 1, px: 2, pb: 2, display: 'flex', justifyContent: 'center'}} onClick={openFull}>
            <Button size="small" color="secondary" variant="contained">
              Показать все
            </Button>
          </Box>
        )}
      </DialogContent>
      {unreadCount > 0 && (
        <DialogActions sx={{p: 0}}>
          <ButtonBase
            onClick={() => markReadAll()}
            sx={{
              p: 0.75,
              width: 1 / 1,
              color: 'primary.500',
              typography: 'body2',
              backgroundColor: 'primary.10',
              transition: 'color .25s, background-color .25s',
              '&:hover': {backgroundColor: 'primary.25', color: 'primary.500'},
            }}
          >
            {isLoading ? 'Помечаем все почитанным' : 'Отметить все как прочитанное'}
          </ButtonBase>
        </DialogActions>
      )}
    </Dialog>
  );
};
