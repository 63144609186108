import {initializeApp} from 'firebase/app';
import {getAnalytics, logEvent} from 'firebase/analytics';

import {firebaseConfig} from 'utils/constants';

let analytics: any;

export const initAnalytics = () => {
  const app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
};

export const LOG_OPEN_APP = (params?: Object) => logEvent(analytics, 'LOG_OPEN_APP', {...params});
