import {useEffect} from 'react';
import {useNavigate} from 'react-router';
import {useQueryParam, StringParam} from 'use-query-params';

const paths = ['post/*', 'user/*', 'stage/*', 'content/*', 'group/*'];
const urlPattern = new RegExp(`^(${paths.map(path => path.replace('*', '\\w+').replace(/\//g, '\\/')).join('|')})$`);

export const RedirectScreen = () => {
  const navigate = useNavigate();
  const [toParam] = useQueryParam('to', StringParam);
  useEffect(() => {
    if (toParam && urlPattern.test(toParam)) {
      window.location.href = `${process.env.REACT_APP_APP_URL_SCHEME}${toParam}`;
    } else navigate('/not-found');
  }, [toParam, navigate]);

  return <div>Подождождите, сейчас вы будете перенаправлены в приложение...</div>;
};
